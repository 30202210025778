<template>
  <div class="el_policy">
    <div class="zczy">
      <div class="wrapper">
        <div class="mtit1">
          <h2 class="cn">宏观政策资讯</h2>
          <div class="line1"></div>
          <div class="en">Macro policy consultation</div>
        </div>
        <div class="zczy-box mb40">
          <ul class="zczy-list">
            <li v-for="(item,index) in hgzczyList"
                :key="index"
                @click="toRouter('article', item)">
              <a>
                <h3 class="tit">{{item.title}}</h3>
                <div class="txt">
                  <div class="txt-l">
                    {{item.articleAbstract}}
                  </div>
                  <div class="txt-r">发布日期：{{item.releaseTime}}</div>
                </div>
              </a>
            </li>
          </ul>
          <div class="zczy-more">
            <a @click="toRouter('information',{type:13,noImage:1,title:'宏观政策资讯',en:'MACRO POLICY CONSULTATION'})">更多<img src="../../../assets/images/icon-more.png" /> </a>
          </div>
        </div>
        <div class="zczy-wrap">
          <div class="zczy-item"
               style="height:385px;">
            <div class="zczy-item-hd">
              <img src="../../../assets/images/icon35.png" />人才引进政策
            </div>
            <div class="zczy-item-bd">
              <ul>
                <li v-for="item in rcyjzcList"
                    :key="item.id"
                    @click="toRouter('article', item)">
                  {{item.title}}
                </li>
              </ul>
            </div>
          </div>
          <div class="zczy-item"
               style="height:385px;">
            <div class="zczy-item-hd">
              <img src="../../../assets/images/icon36.png" />产业园扶持政策
            </div>
            <div class="zczy-item-bd">
              <ul>
                <li v-for="item in cyyfcList"
                    :key="item.id"
                    @click="toRouter('article', item)">
                  {{item.title}}
                </li>
              </ul>
            </div>
          </div>
          <div class="zczy-item"
               style="height:385px;">
            <div class="zczy-item-hd">
              <img src="../../../assets/images/icon37.png" />就业政策
            </div>
            <div class="zczy-item-bd">
              <ul>
                <li v-for="item in jyzcList"
                    :key="item.id"
                    @click="toRouter('article', item)">
                  {{item.title}}
                </li>
              </ul>
            </div>
          </div>
          <div class="zczy-item"
               style="height:325px;">
            <div class="zczy-item-hd">
              <img src="../../../assets/images/icon38.png" />创新创业政策
            </div>
            <div class="zczy-item-bd">
              <ul>
                <li v-for="item in cxcyzcList"
                    :key="item.id"
                    @click="toRouter('article', item)">
                  {{item.title}}
                </li>
              </ul>
            </div>
          </div>
          <div class="zczy-item"
               style="height:325px;">
            <div class="zczy-item-hd">
              <img src="../../../assets/images/icon39.png" />科技创新政策
            </div>
            <div class="zczy-item-bd">
              <ul>
                <li v-for="item in kjcxzcList"
                    :key="item.id"
                    @click="toRouter('article', item)">
                  {{item.title}}
                </li>
              </ul>
            </div>
          </div>
          <div class="zczy-item"
               style="height:325px;">
            <div class="zczy-item-hd">
              <img src="../../../assets/images/icon40.png" />投融资政策
            </div>
            <div class="zczy-item-bd">
              <ul>
                <li v-for="item in trzzcList"
                    :key="item.id"
                    @click="toRouter('article', item)">
                  {{item.title}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: 'el_policy',
      list1: [
        {
          title: '关于印发《国家级人力资源服务产业园管理办法（试行）》的通知',
          content: '为规范国家级人力资源服务产业园建设管理工作，进一步促进人力资源服务业集聚发展，人力资源社会保障部近日制定印发了《国家级人力资源服务产业园管理办法（试行）》（以下简称《办法》）',
          time: '2021/12/13'
        }
      ],
      cyyfcList: [],
      rcyjzcList: [],
      jyzcList: [],
      cxcyzcList: [],
      kjcxzcList: [],
      trzzcList: [],
      hgzczyList: [],
    }
  },
  mounted () {
    this.gethgzczyList();

    this.getHnewsList();// 产业园扶持政策
    this.getRcyj();// 人才引进政策
    this.getjyzcList();
    this.getcxcyzcList();
    this.getkjcxzcList();
    this.gettrzzcList();
  },
  methods: {
    toRouter () {
      console.log(8889999)
      this.$router.push({ name: 'article' })
    },
    getHnewsList () {// 产业园扶持政策
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 15 }).then(res => {
        this.cyyfcList = res.data.result.records;
      })
    },
    getRcyj () {// 人才引进政策
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 14 }).then(res => {
        this.rcyjzcList = res.data.result.records;
      })
    },
    getjyzcList () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 16 }).then(res => {
        this.jyzcList = res.data.result.records;
      })
    },
    getcxcyzcList () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 4, type: 17 }).then(res => {
        this.cxcyzcList = res.data.result.records;
      })
    },
    getkjcxzcList () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 4, type: 18 }).then(res => {
        this.kjcxzcList = res.data.result.records;
      })
    },
    gettrzzcList () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 4, type: 19 }).then(res => {
        this.trzzcList = res.data.result.records;
      })
    },
    gethgzczyList () {// 
      this.api.getArticleList({ pageNo: 1, pageSize: 5, type: 13 }).then(res => {
        this.hgzczyList = res.data.result.records;
      })
    },
    toRouter (routerName, item) {
      let query = {};
      if (routerName === 'information') { // 党建要闻
        query = {
          type: item.type,
          title: item.title,
          en: item.en,
          noImage: item.noImage
        }
      } if (routerName === 'article') {// 去文章详情
        query = {
          id: item.id
        }
      }
      this.newRouter('/' + routerName, query)
    },
  }
}
</script>
<style scoped lang="scss">
.zczy-item li {
  cursor: pointer;
}
.zczy-item-bd li {
  line-height: 63px !important;
  height: 63px;
}

@media screen and (max-width: 1201px) {
  .txt-l {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1201px) {
  .txt-l {
    width: calc(100% - 200px);
  }
}
</style>
